import React from "react";
import "./CouponCard.css";
import moment from "moment";
import dashImage from "../../images/Vector 1.jpg";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import defaultImage from "../../images/emptyImg.jpg";
import { useTranslation } from "react-i18next";

const CouponCard = ({ data, fun }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("create_offer.unlimited_explanation")}
    </Tooltip>
  );
  const { t } = useTranslation();

  function handleErrorImage(e) {
    e.target.src = defaultImage;
  }
  return (
    <div className="coupon-card mt-3">
      <div className="circle-left" />
      <div className="circle-right" />
      <img
        src={`https://api.paybagapp.com/${data?.logo}`}
        alt="store logo"
        className="store-logo"
        onError={handleErrorImage}
      />
      <img src={dashImage} alt="dashed line" className="dashed-line" />
      <div className="description">
        <div className="d-flex justify-content-between flex-wrap w-100">
          <p className="title">
            {data?.title?.length > 10
              ? data?.title?.slice(0, 10) + ".."
              : data?.title}
          </p>
          {data?.isRedeem ? (
            <Badge
              variant="danger light"
              className="d-flex align-items-center "
            >
              {"Expired"}
            </Badge>
          ) : data?.isExpire ? (
            <Badge
              variant="danger light"
              className="d-flex align-items-center "
            >
              {"Expired"}
            </Badge>
          ) : (
            <Badge
              variant="success light"
              className="d-flex align-items-center hover-scale"
              onClick={() =>
                data?.isRedeem
                  ? undefined
                  : fun(data?.id, "coupon", data?.pointNeeded)
              }
            >
              {"Redeem"}
            </Badge>
          )}
        </div>
        <p className="valid-date">
          {t("offer_card.time_per_user")}:
          {data?.oncePerUser > 1000000
            ? t("create_offer.unlimited")
            : data?.oncePerUser}
          {data?.oncePerUser > 1000000 && (
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <i
                className="fa-sharp fa-solid fa-circle-info p-1"
                style={{ fontSize: "20px", color: "#042e77" }}
              ></i>
              {/* <Button variant="link" className="p-0 font-w600"></Button> */}
            </OverlayTrigger>
          )}
        </p>
        <p className="valid-date">
          {t("offer_card.validation_from")}: {data?.availableNumber}
        </p>

        <div className="d-flex align-items-center justify-content-between w-100">
          <p className="valid-date">
            {t("offer_card.h4")}:{" "}
            {moment(data?.validDate).format("Do MMMM YYYY")}
          </p>
          <p className="sub-title">
            {t("offer_card.price")}: {data?.pointPrice}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
