import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import { toast } from "react-hot-toast";
import { logOutApi } from "../../services/User/UserService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const STORE_ID_ACTION = "[login action] store id";
export const VERIFY_STATUS_ACTION = "[Verify status action] verify";

export function signupAction(
  formData,
  history,
  setLoader,
  t,
  setShowEmailPopup
) {
  setLoader(true);

  return (dispatch) => {
    signUp(formData)
      .then((response) => {
        dispatch(confirmedSignupAction(response.data));

        setLoader(false);
        // toast.success(`${t("toast_messages.signUp_success_message")}`);
        // history.push("/login");
        setShowEmailPopup(true);
      })
      .catch((error) => {
        const errorMessage = formatError(
          error?.response?.data?.data
            ? error?.response?.data?.data
            : error?.response?.data?.message
        );
        dispatch(signupFailedAction(errorMessage));
        setLoader(false);
      });
  };
}

// export function logoutAction(history) {
//   return () => {
//     logOutApi()
//       .then((response) => {
//         console.log("HDbdjbj");
//         localStorage.removeItem("loyaltyToken");
//         localStorage.removeItem("role");
//         localStorage.removeItem("storeId");
//         localStorage.removeItem("verifyStatus");
//         localStorage.removeItem("requestId");
//         history.push("/login");
//       })
//       .catch((error) => {
//         console.log(error.response, "dknkwnkn");
//       });
//   };
// }
export function logout(history) {
  history.push("/login");
  localStorage.removeItem("loyaltyToken");

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history, setLoader, rememberMe) {
  setLoader(true);
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        if (response) {
          console.log(response?.data?.data?.storeId);

          localStorage.setItem("name", response?.data?.data?.name);
          localStorage.setItem("storeId", response?.data?.data?.storeId);
          localStorage.setItem(
            "verifyStatus",
            response?.data?.data?.verifyStatus
          );
          localStorage.setItem(
            "role",
            JSON.stringify(response?.data?.data?.type)
          );

          if (rememberMe) {
            localStorage.setItem('rememberedCredentials', JSON.stringify({ email, password }));
          } else {
            localStorage.removeItem('rememberedCredentials');
          }

          saveTokenInLocalStorage(response?.data?.value?.token);
          setLoader(false);
        }
        dispatch(loginConfirmedAction(response?.data?.value?.token));
        dispatch(storeIdAction(response?.data?.data?.storeId));
        if (response?.data?.data?.type) {
          history.push("/");
        } else {
          history.push("/coupon-verification");
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error?.response?.data?.message);
        setLoader(false);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}
export function storeIdAction(storeId) {
  return {
    type: STORE_ID_ACTION,
    payload: storeId,
  };
}
export function verifyAccess(status) {
  return {
    type: VERIFY_STATUS_ACTION,
    payload: status,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
