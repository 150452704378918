import { useTranslation } from "react-i18next";
import moment from "moment";
import { Card, Table, Spinner } from "react-bootstrap";
import { BsCoin } from "react-icons/bs";
import { HiMiniUsers } from "react-icons/hi2";
import { PiSpinnerFill } from "react-icons/pi";
import { GiBowTieRibbon } from "react-icons/gi";
import Lottie from "lottie-react";
import animationData from "../../images/winner.json";
import { useEffect, useState } from "react";
import winIcon from "../../images/medal.png";
import "./SpinWheelDetails.css";
import { getWheelHistoryApi } from "../../services/User/UserService";
import Pagination from "../common/Pagination";

const DataCard = ({ title, data, Icon }) => (
  <div className="data-card">
    <div className="icon">
      <Icon />
    </div>
    <div className="details">
      <span className="title">{title}</span>
      <span className="percentage">{data}</span>
    </div>
  </div>
);
const WinnerCard = ({ title, data, Icon }) => (
  <div className="winner-card">
    <div className="winner-profile">
      <div className="d-flex w-100">
        <div className="icon">
          <img src={winIcon} alt="img" height={30} width={30} />
        </div>
        <div className="details">
          <h4 className="percentage">#1 {data.name}</h4>
          <h4 className="percentage">
            +{data?.countryCode} {data?.phoneNumber}
          </h4>
          <h4 className="percentage">{data?.email}</h4>
        </div>
      </div>
      <div className="animation-badge">
        <Lottie animationData={animationData} />
      </div>
    </div>
  </div>
);
export default function SpinWheelDetails({ location }) {
  const { t } = useTranslation();
  const item = location.state;

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;

  // Fetch Spin History Data
  const getTableData = () => {
    setLoader(true);
    getWheelHistoryApi(item.id, currentPage, limit)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        setPageCount(Math.ceil(response.data.data.total / limit));
      })
      .catch(() => setLoader(false));
  };

  useEffect(() => {
    getTableData();
  }, [item?.id, currentPage]);

  return (
    <div className="spin-wheel-details m-0">
      <h3 className="p-2">{t("spin_wheel_details.wheelContestDetails")}</h3>

      {item?.winner && (
        <WinnerCard data={item.winner} title={t("spin_wheel_details.winner")} />
      )}

      <Card className="p-3 mb-4">
        <h3 className="mb-3">{item?.name}</h3>
        <div className="row">
          <div className="col-md-4 contest-profile">
            <div className="timing-section">
              <p>
                <strong>{t("spin_wheel_details.startDate")}</strong>{" "}
                {moment(item?.startTime).format("ll")}
              </p>
              <p>
                <strong>{t("spin_wheel_details.endDate")}</strong>{" "}
                {moment(item?.endTime).format("ll")}
              </p>
            </div>
            <p>
              <strong>{t("spin_wheel_details.message")}</strong> {item?.message}
            </p>
          </div>
          <div className="col-md-8 d-flex flex-wrap justify-content-end">
            <DataCard
              title={t("spin_wheel_details.requiredPoints")}
              data={item.pointsRequired}
              Icon={BsCoin}
            />
            <DataCard
              title={t("spin_wheel_details.allowPerUser")}
              data={item.timeAllowPerUser}
              Icon={HiMiniUsers}
            />
            <DataCard
              title={t("spin_wheel_details.totalSpin")}
              data={item.totalNumberSpin}
              Icon={PiSpinnerFill}
            />
            <DataCard
              title={t("spin_wheel_details.loyaltyTier")}
              data={item?.specifyLoyaltyTier?.join(", ")}
              Icon={GiBowTieRibbon}
            />
          </div>
        </div>
      </Card>

      <Card className="p-3 mb-4">
        <h3>{t("spin_wheel_details.spinWheelPrizes")}</h3>
        <div className="d-flex flex-wrap">
          {item?.wheelPrizes?.map((spin, i) => (
            <DataCard
              key={i}
              title={spin?.prize}
              data={`${t("spin_wheel_details.quantity")}: ${spin?.quantity}`}
              Icon={() => <i className="flaticon-381-help"></i>}
            />
          ))}
        </div>
      </Card>

      <Card className="p-3 w-100">
        <div className="d-flex align-items-center mb-3">
          <h3>{t("spin_wheel_details.spinByUsers")}</h3>
        </div>
        {loader ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("spin_wheel_details.name")}</th>
                  <th>{t("spin_wheel_details.contact")}</th>
                  <th>{t("spin_wheel_details.email")}</th>
                  <th>{t("spin_wheel_details.prize")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.wheels?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.User.name}</td>
                    <td>{item?.User?.phoneNumber}</td>
                    <td>{item?.User.email}</td>
                    <td>{item?.prize}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {!data?.wheels?.length && (
              <div className="text-center my-5">
                {t("spin_wheel_details.dataNotFound")}
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <span>
                {t("spin_wheel_details.totalSpin")}:{" "}
                <strong>{data?.total || 0}</strong>
              </span>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
