import axiosInstance from "../AxiosInstance";
import {
  ADD_STORE,
  GET_STORE,
  EDIT_STORE,
  DELETE_STORE,
  ADD_PROGRAM,
  GET_OFFERS,
  ADD_OFFER,
  EDIT_OFFER,
} from "./StoreApiEndPoints";

export function dashboardApi() {
  return axiosInstance.get("vendor/dashboard");
}

//====================================Vendor Profile =================================

export function getVendorProfileApi() {
  return axiosInstance.get("vendor/profileDetails");
}

export function getIndustryApi(type) {
  return axiosInstance.get(`user/auth/industries?type=${type}`);
}

export async function editVendor(postData) {
  const response = await axiosInstance.put(
    "user/auth/editProfileVendor",
    postData
  );
  return response;
}
export async function addStore(formData) {
  const response = await axiosInstance.post(ADD_STORE, formData);
  return response;
}
export async function editStore(formData) {
  const response = await axiosInstance.put(EDIT_STORE, formData);
  return response;
}

export async function uploadDocuments(formData) {
  const response = await axiosInstance.put(
    "user/auth/uploadDocuments",
    formData
  );
  return response;
}

export function getStore(currentPage, limit) {
  return axiosInstance.get(GET_STORE + `?page=${currentPage}&limit=${limit}`);
}
export function getLoyaltyUsersApi(currentPage, limit, search, filterType) {
  return axiosInstance.get(
    "vendor/loyaltyProgramUsers" +
      `?page=${currentPage}&limit=${limit}&tier=${filterType}&search=${search}`
  );
}
export function getStoreDetailsApi(id) {
  return axiosInstance.get(`vendor/getStoreDetail?storeId=${id}`);
}

export function deleteStore(id) {
  return axiosInstance.delete(`vendor/deleteStore?storeId=${id}`);
}

export async function addLoyaltyProgramApi(formData) {
  const response = await axiosInstance.post(ADD_PROGRAM, formData);
  return response;
}
export async function editLoyaltyProgramApi(formData) {
  const response = await axiosInstance.put(
    "vendor/editLoyaltyProgram",
    formData
  );
  return response;
}

//////////////////////////////////////////Offers API================================
export function getOfferApi(id) {
  return axiosInstance.get(GET_OFFERS + `?programId=${id}`);
}
export async function addOfferApi(formData) {
  const response = await axiosInstance.post(ADD_OFFER, formData);
  return response;
}
export async function editOfferApi(formData) {
  const response = await axiosInstance.put(EDIT_OFFER, formData);
  return response;
}
export function deleteOfferApi(id) {
  return axiosInstance.delete(`vendor/deleteOffer?offerId=${id}`);
}
export function deleteProgramApi(id) {
  return axiosInstance.delete(`vendor/deleteLoyaltyProgram?Id=${id}`);
}
export async function addTierApi(
  silverTier,
  platinumTier,
  goldTier,
  diamondTier,
  storeId
) {
  const postData = {
    silver: silverTier.silver,
    silverStatus: silverTier.silverStatus,
    platinum: platinumTier.platinum,
    platinumStatus: platinumTier.platinumStatus,
    gold: goldTier.gold,
    diamond: diamondTier.diamond,
    diamondStatus: diamondTier.diamondStatus,
    goldStatus: goldTier.goldStatus,

    storeId: storeId,
  };
  const response = await axiosInstance.put("vendor/setTiers", postData);
  return response;
}

//============================Create Item Api =============================
export function getItemsApi() {
  return axiosInstance.get(`vendor/loyaltyItemList?page=${"0"}&limit=${"100"}`);
}

export async function addItemApi(formData) {
  const response = await axiosInstance.post("vendor/loyaltyItem", formData);
  return response;
}

export function getAccessCodeApi() {
  return axiosInstance.get(`vendor/loyaltyItemList?page=${"0"}&limit=${"100"}`);
}

export async function sendPointToLoyaltyProgramApi(formData) {
  const response = await axiosInstance.post(
    "vendor/addPointToloyaltyProgram",
    formData
  );
  return response;
}
//=============================SubVendor` =============================

export function getSubVendorApi() {
  return axiosInstance.get(`vendor/subVendorList?page=${"0"}&limit=${"100"}`);
}

export async function addSubVendorApi(formData) {
  const response = await axiosInstance.post("vendor/createSubVendor", formData);
  return response;
}

export function deleteEmployee(id) {
  return axiosInstance.delete(`vendor/deleteSubVendor?Id=${id}`);
}
////============================================================Coupon Verification =================================================================
export function getOfferCouponListApi(id, type) {
  // console.log(id);
  return axiosInstance.get(
    "vendor/checkRequestStatus" + `?requestId=${id}&type=${type}`
  );
}
export async function verifyCouponCodeApi(data) {
  const response = await axiosInstance.post("vendor/sendRequest", data);
  return response;
}
export async function redeemOffersApi(data) {
  const response = await axiosInstance.post(
    "vendor/verifyOffersOrCoupon",
    data
  );
  return response;
}

//===============Terms =================================================================
export function getVendorTermsApi() {
  return axiosInstance.get("Vendor/termAndConditions");
}
export function getVendorPolicyApi() {
  return axiosInstance.get("Vendor/privacyPolicy");
}
