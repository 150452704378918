import MetisMenu from "metismenujs";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import couponIcon from "../../../images/promo-code.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStore } from "@fortawesome/free-solid-svg-icons";
import { LuFerrisWheel } from "react-icons/lu";


const MM = ({ children }) => {
  const elRef = useRef(null);

  useEffect(() => {
    const mm = new MetisMenu(elRef.current);

    return () => {
      mm.dispose();
    };
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" ref={elRef}>
        {children}
      </ul>
    </div>
  );
};

const SideBar = ({ t }) => {
  const [loveEmoji, setLoveEmoji] = useState(false);
  const [access, setAccess] = useState(null);
  const someReduxState = useSelector((state) => state.auth.verifyStatus);
  const [isVerify, setIsVerify] = useState(null);
  const [isPrime, setIsPrime] = useState(false);

  useEffect(() => {
    let btn;
    let aaa;

    setTimeout(() => {
      btn = document.querySelector(".nav-control");
      aaa = document.querySelector("#main-wrapper");
    }, 1000);

    const toggleFunc = () => aaa?.classList.toggle("menu-toggle");
    btn?.addEventListener("click", toggleFunc);
    setLoveEmoji(true);

    fetchData();

    return () => {
      btn?.removeEventListener("click", toggleFunc);
    };
  }, []);

  useEffect(() => {
    const isPremium = localStorage.getItem("isPremium") === "true";
    const verifyStatus = localStorage.getItem("verifyStatus")
      ? localStorage.getItem("verifyStatus")
      : someReduxState;
    if (verifyStatus) {
      setIsVerify(verifyStatus);
    } else {
      console.error("verifyStatus data not found in local storage");
    }
    setIsPrime(isPremium);
  }, [someReduxState]);

  const fetchData = async () => {
    try {
      const permissions = localStorage.getItem("role");
      if (permissions) {
        setAccess(JSON.parse(permissions));
      } else {
        console.error("Permissions data not found in local storage");
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  let path = window.location.pathname.split("/");
  path = path[path.length - 1];

  const dashBoard = [
      "",
      "analytics",
      "events",
      "order-list",
      "general-customers",
      "reviews",
      "task",
    ],
    Notification = ["notification-management"];
  const users = ["store-management", "store-details", "program-offers"];
  const Coupon = ["coupon-verification"];
  const SubVendor = ["subVendor-management"];
  const Contest = ["contest-management"];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {access === "Vendor" ? (
            <>
              <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/">
                  <i className="flaticon-381-networking"></i>
                  <span className="nav-text" style={{ paddingLeft: "-0.5rem" }}>
                    {t("sidebar_menu.dashboard")}
                  </span>
                </Link>
              </li>
              {isVerify === "Accepted" && (
                <>
                  <li className={`${users.includes(path) ? "mm-active" : ""}`}>
                    <Link to="store-management" className="ai-icon">
                      <i class="fa-solid fa-store"></i>
                      <span
                        className="nav-text"
                        style={{ paddingLeft: "-0.5rem" }}
                      >
                        {t("sidebar_menu.store_management")}
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`${SubVendor.includes(path) ? "mm-active" : ""}`}
                  >
                    <Link to="subVendor-management" className="ai-icon">
                      <i class="ti-user"></i>
                      <span
                        className="nav-text"
                        style={{ paddingLeft: "-0.5rem" }}
                      >
                        {t("sidebar_menu.employee")}
                      </span>
                    </Link>
                  </li>
                  <li className={`${Coupon.includes(path) ? "mm-active" : ""}`}>
                    <Link className="ai-icon" to="/coupon-verification">
                      <img src={couponIcon} height={20} />
                      <span
                        className="nav-text"
                        style={{ paddingLeft: "0.625rem" }}
                      >
                        {t("sidebar_menu.coupon_verify")}
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`${Contest.includes(path) ? "mm-active" : ""}${
                      isPrime === false ? "disabled-row" : ""
                    }`}
                    // className={`${Contest.includes(path) ? "mm-active" : ""}`}
                  >
                    <Link to="/contest-management" className="ai-icon ">
                    <LuFerrisWheel 
                      style={{
                        width: '20px',
                        color: Contest.includes(path) ? "#042F77" : "#969BA0"
                      }} 
                      className={`color-icons-ki ${Contest.includes(path) ? "mm-active" : ""}`}
                    />
                      <span
                        className="nav-text"
                        style={{ paddingLeft: "-0.5rem" }}
                      >
                        {t("sidebar_menu.contest")}
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      Notification.includes(path) ? "mm-active" : ""
                    }${isPrime === false ? "disabled-row" : ""}`}
                  >
                    <Link to="/notification-management" className="ai-icon ">
                      <i className="flaticon-381-ring"></i>
                      <span
                        className="nav-text"
                        style={{ paddingLeft: "-0.5rem" }}
                      >
                        {t("sidebar_menu.notification")}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </>
          ) : (
            <>
              <li className={`${Coupon.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/coupon-verification">
                  <i className="flaticon-381-networking"></i>
                  <span className="nav-text" style={{ paddingLeft: "-0.5rem" }}>
                    {t("sidebar_menu.coupon_verify")}
                  </span>
                </Link>
              </li>
            </>
          )}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default withTranslation()(SideBar);
