import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import {
  addSpinWheelApi,
  editWheelOfferApi,
} from "../../services/User/UserService";
import MultiSelectDropdown from "../common/MultiSelectDropdown";
import { useTranslation } from "react-i18next"; // Importing useTranslation

const AddSpinWheelOffers = (props) => {
  const { t } = useTranslation(); // Access the translation function
  const options = [
    { label: "Silver", value: "silver" },
    { label: "Gold", value: "gold" },
    { label: "Platinum", value: "platinum" },
    { label: "Diamond", value: "diamond" },
  ];
  const { title, successMessage, selectedItem } = props?.location?.state;
  const [wheelPrizes, setWheelPrizes] = useState(
    Array(6)
      .fill(null)
      .map(() => ({ prize: "", isPrize: false, quantity: 0 }))
  );
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalNumberSpin, setTotalNumberSpin] = useState("");
  const [pointsRequired, setPointsRequired] = useState("");
  const [timeAllowPerUser, setTimeAllowPerUser] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [tiers, setTiers] = useState([]);

  const specifyLoyaltyTierValues = tiers?.map((tier) => tier?.value);

  const handlePrizeChange = (index, value) => {
    const isPrize = value === "Prize";
    const newPrizes = [...wheelPrizes];

    newPrizes[index] = {
      ...newPrizes[index],
      prize: isPrize ? "" : "Better Luck Next Time",
      isPrize,
      quantity: isPrize ? newPrizes[index].quantity : 0,
    };
    setWheelPrizes(newPrizes);

    if (errors[`prizeName${index}`]) {
      const newErrors = { ...errors };
      delete newErrors[`prizeName${index}`];
      setErrors(newErrors);
    }
  };

  const handlePrizeInputChange = (index, value) => {
    // Only allow numbers and decimal points
    if (value && !/^\d*\.?\d*$/.test(value)) {
      return;
    }

    const newPrizes = [...wheelPrizes];
    newPrizes[index] = {
      ...newPrizes[index],
      prize: value,
    };
    setWheelPrizes(newPrizes);

    if (errors[`prizeName${index}`]) {
      const newErrors = { ...errors };
      delete newErrors[`prizeName${index}`];
      setErrors(newErrors);
    }
  };

  const handleQuantityChange = (index, value) => {
    // Only allow positive integers
    if (value && !/^\d*$/.test(value)) {
      return;
    }

    const newPrizes = [...wheelPrizes];
    const quantity = value === '' ? '' : Math.max(0, parseInt(value, 10));
    newPrizes[index].quantity = quantity;
    setWheelPrizes(newPrizes);
  };

  const handleChange =
    (setter, field, isNumber = false) =>
    (e) => {
      const value = isNumber ? Number(e.target.value) : e.target.value;
      setter(value);
      if (errors[field]) {
        const newErrors = { ...errors };
        delete newErrors[field];
        setErrors(newErrors);
      }
    };

  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = t("addEditSpinContest.nameRequired");
    }

    wheelPrizes.forEach((prize, index) => {
      if (prize.isPrize && !prize.prize) {
        newErrors[`prizeName${index}`] = t("addEditSpinContest.prizeRequired");
      }
      if (prize.isPrize && !isNaN(prize.prize)) {
        let formattedPrize = prize.prize;
        if (formattedPrize.startsWith(".")) {
          formattedPrize = "0" + formattedPrize;
          prize.prize = formattedPrize;
        }
        if (Number(prize.prize) <= 0) {
          newErrors[`prizeName${index}`] = t(
            "addEditSpinContest.prizeGreaterThanZero"
          );
        }
      }
    });

    if (
      !totalNumberSpin ||
      isNaN(totalNumberSpin) ||
      Number(totalNumberSpin) <= 0
    ) {
      newErrors.totalNumberSpin = t(
        "addEditSpinContest.totalNumberSpinRequired"
      );
    }
    if (
      pointsRequired !== "" &&
      (isNaN(pointsRequired) || Number(pointsRequired) < 0)
    ) {
      newErrors.pointsRequired = t("addEditSpinContest.pointsRequiredError");
    }
    if (
      !timeAllowPerUser ||
      isNaN(timeAllowPerUser) ||
      Number(timeAllowPerUser) <= 0
    ) {
      newErrors.timeAllowPerUser = t(
        "addEditSpinContest.timeAllowPerUserRequired"
      );
    }
    if (Number(timeAllowPerUser) > Number(totalNumberSpin)) {
      newErrors.timeAllowPerUser = t(
        "addEditSpinContest.timeAllowPerUserLimitError"
      );
    }
    if (!startTime) {
      newErrors.startTime = t("addEditSpinContest.startDateRequired");
    }
    if (!endTime) {
      newErrors.endTime = t("addEditSpinContest.endDateRequired");
    }
    if (!message) {
      newErrors.message = t("addEditSpinContest.messageRequired");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatDate = (isoDate) => {
    return isoDate ? isoDate.slice(0, 10) : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const showAlert = title === "Edit Wheel Contest";
    let proceed = true;

    if (showAlert) {
      const result = await Swal.fire({
        title: t("addEditSpinContest.areYouSure"),
        text: t("addEditSpinContest.confirmUpdate"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("addEditSpinContest.confirmButtonText"),
        cancelButtonText: t("addEditSpinContest.cancelButtonText"),
      });

      proceed = result.isConfirmed;
    }

    if (proceed) {
      setLoader(true);
      try {
        const postData = {
          wheelPrizes,
          name,
          specifyLoyaltyTier: specifyLoyaltyTierValues,
          totalNumberSpin,
          timeAllowPerUser,
          pointsRequired,
          startTime,
          endTime,
          message,
        };

        if (title === "Edit Wheel Contest") {
          postData.contestId = selectedItem.id;
        }

        const response =
          title === "Edit Wheel Contest"
            ? await editWheelOfferApi(postData)
            : await addSpinWheelApi(postData);

        props?.history.push("/contest-management");
        toast.success(successMessage);
      } catch (e) {
        toast.error(
          e?.response?.data?.data
            ? e?.response?.data?.data
            : e?.response?.data?.message
        );
      } finally {
        setLoader(false);
      }
    } else {
      props?.history.push("/contest-management");
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setWheelPrizes(
        selectedItem?.wheelPrizes ||
          Array(6)
            .fill(null)
            .map(() => ({ prize: "", isPrize: false }))
      );
      const prefilledTiers = selectedItem.specifyLoyaltyTier
        .map((value) => options.find((option) => option.value === value))
        .filter(Boolean);
      setTiers(prefilledTiers);
      setName(selectedItem?.name || "");
      setTotalNumberSpin(selectedItem?.totalNumberSpin || "");
      setTimeAllowPerUser(selectedItem?.timeAllowPerUser || "");
      setStartTime(formatDate(selectedItem?.startTime) || "");
      setPointsRequired(selectedItem?.pointsRequired || "");
      setEndTime(formatDate(selectedItem?.endTime) || "");
      setMessage(selectedItem?.message || "");
    }
  }, [selectedItem]);
  return (
    <div>
      <h3 className="pl-4">{title}</h3>
      <form onSubmit={handleSubmit} className="p-4">
        <div>
          <div className="form-group">
            <label>{t("addEditSpinContest.contestNameLabel")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t("addEditSpinContest.contestNamePlaceholder")}
              value={name}
              onChange={handleChange(setName, "name")}
            />
            {errors.name && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.contestNameError")}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.startDateLabel")}</label>
            <input
              type="date"
              className="form-control"
              placeholder={t("addEditSpinContest.startDatePlaceholder")}
              value={startTime}
              onChange={handleChange(setStartTime, "startTime")}
            />
            {errors.startTime && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.startDateError")}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.endDateLabel")}</label>
            <input
              type="date"
              className="form-control"
              placeholder={t("addEditSpinContest.endDatePlaceholder")}
              value={endTime}
              onChange={handleChange(setEndTime, "endTime")}
            />
            {errors.endTime && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.endDateError")}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.loyaltyTierLabel")}</label>
            <MultiSelectDropdown
              options={options}
              selected={tiers}
              setSelected={setTiers}
            />
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.totalNumberOfSpinsLabel")}</label>
            <input
              type="number"
              className="form-control"
              placeholder={t(
                "addEditSpinContest.totalNumberOfSpinsPlaceholder"
              )}
              value={totalNumberSpin}
              onChange={handleChange(
                setTotalNumberSpin,
                "totalNumberSpin",
                true
              )}
            />
            {errors.totalNumberSpin && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.totalNumberOfSpinsError")}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.pointsRequiredLabel")}</label>
            <input
              type="number"
              className="form-control"
              placeholder={t("addEditSpinContest.pointsRequiredPlaceholder")}
              value={pointsRequired}
              onChange={handleChange(setPointsRequired, "pointsRequired", true)}
            />
            {errors.pointsRequired && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.pointsRequiredError")}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.timesAllowedPerUserLabel")}</label>
            <input
              type="number"
              className="form-control"
              placeholder={t(
                "addEditSpinContest.timesAllowedPerUserPlaceholder"
              )}
              value={timeAllowPerUser}
              onChange={handleChange(
                setTimeAllowPerUser,
                "timeAllowPerUser",
                true
              )}
            />
            {errors.timeAllowPerUser && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.timesAllowedPerUserError")}
              </p>
            )}
            {errors.timeAllowPerUserLimitError && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.timesAllowedPerUserLimitError")}
              </p>
            )}
          </div>
          <h4 className="mt-4">
            {t("addEditSpinContest.spinWheelPrizesHeader")}
          </h4>
          {/* Prize fields */}
          <div>
            {wheelPrizes.map((prize, index) => (
              <div key={index}>
                <div
                  className="form-group d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <select
                    className="form-control"
                    value={
                      prize.isPrize
                        ? "Prize"
                        : prize.prize === ""
                        ? ""
                        : "Better Luck Next Time"
                    }
                    onChange={(e) => handlePrizeChange(index, e.target.value)}
                  >
                    <option value="" disabled>
                      {t("addEditSpinContest.selectPrizeOption")}
                    </option>
                    <option value="Prize">
                      {t("addEditSpinContest.selectPrizeOption")}
                    </option>
                    <option value="Better Luck Next Time">
                      {t("addEditSpinContest.betterLuckNextTime")}
                    </option>
                  </select>
                  {prize.isPrize && (
                    <div
                      className="d-flex align-items-center w-100"
                      style={{ gap: "10px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={prize.prize}
                        onChange={(e) =>
                          handlePrizeInputChange(index, e.target.value)
                        }
                        placeholder={t(
                          "addEditSpinContest.prizeInputPlaceholder"
                        )}
                      />
                      <input
                        type="text"
                        className="form-control"
                        value={prize.quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                        placeholder={t(
                          "addEditSpinContest.quantityInputPlaceholder"
                        )}
                      />
                    </div>
                  )}
                </div>
                {errors[`prizeName${index}`] && (
                  <p style={{ color: "red" }}>
                    {t("addEditSpinContest.prizeRequiredError")}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div className="form-group">
            <label>{t("addEditSpinContest.messageLabel")}</label>
            <textarea
              style={{ minHeight: "100px" }}
              className="form-control"
              placeholder={t("addEditSpinContest.messagePlaceholder")}
              value={message}
              onChange={handleChange(setMessage, "message")}
            />
            {errors.message && (
              <p style={{ color: "red" }}>
                {t("addEditSpinContest.messageError")}
              </p>
            )}
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <button className="btn btn-primary" type="submit">
              {t("addEditSpinContest.saveButton")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSpinWheelOffers;
