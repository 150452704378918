import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { useTranslation } from "react-i18next"; // Import hook

import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import Pagination from "../common/Pagination";
import UserTableSkeleton from "../components/SkeletonPages/UserTableSkeleton";
import { deleteWheelApi, getContestApi } from "../../services/User/UserService";

export default function SpinWheel(props) {
  const { t } = useTranslation(); // i18n Translation Hook

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;

  function getTableData() {
    setLoader(true);
    getContestApi(currentPage, limit)
      .then((response) => {
        setData(response.data.data);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  async function deleteWheelHistory(item) {
    setDeleteModal(true);
    setSelectedItem(item);
  }

  function onSendVenderItem(item) {
    props.history.push({
      pathname: "./spin-wheel-details",
      state: item,
    });
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pl-3 pr-3 pb-3">
        <h3 className="m-0">{t("spinWheel.title")}</h3>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() =>
              props.history.push({
                pathname: "/add-contest-form",
                state: {
                  title: t("spinWheel.addContestTitle"),
                  successMessage: t("spinWheel.addContestSuccess"),
                },
              })
            }
          >
            {t("spinWheel.addContest")}
          </button>
        </div>
      </div>

      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>{t("spinWheel.tableHeaders.name")}</th>
                  <th className="text-center">
                    {t("spinWheel.tableHeaders.requiredPoint")}
                  </th>
                  <th>{t("spinWheel.tableHeaders.startDate")}</th>
                  <th>{t("spinWheel.tableHeaders.endDate")}</th>
                  <th>{t("spinWheel.tableHeaders.createdAt")}</th>
                  <th className="text-center">
                    {t("spinWheel.tableHeaders.status")}
                  </th>
                  <th>{t("spinWheel.tableHeaders.action")}</th>
                </tr>
              </thead>
              {loader ? (
                <UserTableSkeleton count={8} />
              ) : (
                <tbody>
                  {data?.contests?.map((item, i) => (
                    <tr key={i}>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item.name}
                      </td>
                      <td
                        className="text-center"
                        onClick={() => onSendVenderItem(item)}
                      >
                        {item.pointsRequired}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item.startTime).format("ll")}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item.endTime).format("ll")}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item.createdAt).format("ll")}
                      </td>
                      <td className="text-center">
                        {item.isExpire ? (
                          <Badge variant="danger light">
                            {t("spinWheel.status.expired")}
                          </Badge>
                        ) : (
                          <Badge variant="success light">
                            {t("spinWheel.status.active")}
                          </Badge>
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {!item?.isExpire && (
                              <Dropdown.Item
                                onClick={() =>
                                  props.history.push({
                                    pathname: "/add-contest-form",
                                    state: {
                                      selectedItem: item,
                                      successMessage: t(
                                        "spinWheel.editContestSuccess"
                                      ),
                                      title: t("spinWheel.editContestTitle"),
                                    },
                                  })
                                }
                              >
                                {t("spinWheel.actions.edit")}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => deleteWheelHistory(item)}
                            >
                              {t("spinWheel.actions.delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>

            {data?.total === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5">
                {t("common.empty_table")}
              </div>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-between align-items-center pt-3">
              <div className="dataTables_info">
                {t("spinWheel.totalWheelContest")}
                <span
                  style={{
                    color: "#042e77",
                    border: "1px solid #042e77",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {data?.total}
                </span>
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      {deleteModal && (
        <ConfirmationModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          successMessage={t("spinWheel.deleteSuccess")}
          api={deleteWheelApi}
          refresh={getTableData}
          content={t("spinWheel.deleteConfirmation")}
          data={selectedItem}
        />
      )}
    </div>
  );
}
